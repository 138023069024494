import {
  ServiceReportDetailStatus as WebApiServiceReportDetailStatus,
  ServiceReportDetail as WebApiServiceReportDetail,
  ServiceReportDetailDetail as WebApiServiceReportDetailDetail,
  ServiceReportDetailDetailConstructions,
  ServiceReportDetailDetailUsedParts,
  ServiceReportDetailDetailWorkingTimes as WebApiServiceReportDetailDetailWorkingTimes,
  ServiceReportDetailCreate as WebApiServiceReportDetailCreate,
  ServiceReportDetailUpdate as WebApiServiceReportDetailUpdate,
  ServiceReportSaveType as WebApiServiceReportSaveType,
  ServiceReportConstructionCompleteStatus,
  ServiceReportDetailDetailFiles,
  ServiceReportDetailConstructionsFiles as WebApiServiceReportDetailConstructionsFiles,
  ServiceReportDetailConstructionsFilesConstructions,
} from "../webapi/generated";
import { AttachmentFile } from "./diagram";
import { SERVICE_REPORT_OFFLINE_STATUS } from "./serviceReport";

/**
 * サービスレポート明細の登録種別
 */
export type ServiceReportDetailSaveType = WebApiServiceReportSaveType;

/**
 * サービスレポート明細情報
 */
export type ServiceReportDetail = WebApiServiceReportDetail;

/**
 * サービスレポート明細情報の下書き付き
 */
export type ServiceReportDetailWithDraft = Omit<
  ServiceReportDetail,
  "id" | "number" | "status" | "constructions" | "hasSignature"
> & {
  id: string | number;
  number?: number;
  status: ServiceReportDetailStatus;
  hasDraft?: boolean;
  hasSignature?: boolean;
};

/**
 * サービスレポート明細情報（詳細）
 */
export type ServiceReportDetailDetail = Omit<
  WebApiServiceReportDetailDetail,
  | "id"
  | "number"
  | "versionId"
  | "status"
  | "lastCheckedAt"
  | "lastCheckedUserId"
  | "lastCheckedUserName"
  | "customerSignedAt"
  | "constructions"
  | "workingTimes"
> & {
  id: string;
  number?: number;
  serviceReportId: string;
  versionId?: number;
  status: ServiceReportDetailStatus;
  lastCheckedAt?: string;
  lastCheckedUserId?: string;
  lastCheckedUserName?: string;
  customerSignedAt?: string;
  hasDraft: boolean;
  constructions: ServiceReportDetailDetailConstruction[];
  workingTimes: ServiceReportDetailDetailWorkingTimes[];
};

/**
 * 作業レポート転記先用サービスレポート明細情報
 */
export type ServiceReportDetailPasteConstructions = {
  number?: number;
  serviceReportDetailId?: number;
  workers: string;
  constructions: Omit<ServiceReportDetailDetailConstruction, "usedParts">[];
};

/**
 * サービスレポート明細作業レポート転記元用情報
 */
export type ServiceReportDetailCopyConstructions = Omit<
  ServiceReportDetail,
  "id" | "number" | "status" | "hasSignature" | "constructions"
> & {
  id?: number;
  number?: number;
  constructions: Omit<ServiceReportDetailDetailConstruction, "usedParts">[];
};

/**
 * サービスレポート明細情報（詳細）に紐付く機種別工事情報
 */
export type ServiceReportDetailDetailConstruction = Omit<
  ServiceReportDetailDetailConstructions,
  "id" | "usedParts" | "serviceReportConstructionGroupUuid"
> & {
  id?: number;
  key?: string;
  workOrderNo?: string;
  usedParts: ServiceReportDetailDetailConstructionUsedParts[];
  serviceReportConstructionGroupUuid?: string;
};

/**
 * サービスレポート明細情報（詳細）に紐付く機種別工事情報の使用部品
 */
export type ServiceReportDetailDetailConstructionUsedParts = Omit<
  ServiceReportDetailDetailUsedParts,
  "id" | "uuid" | "isSerialItem"
> & {
  id?: number;
  workOrderNo?: string;
  uuid?: string;
  isSerialItem?: boolean;
};

/**
 * サービスレポート明細の工事情報に紐づく添付ファイル
 */
export type ServiceReportDetailDetailFile = ServiceReportDetailDetailFiles;

/**
 * サービスレポート明細情報（詳細）に紐付く機種別工事情報の工数情報
 */
export type ServiceReportDetailDetailWorkingTimes = Omit<
  WebApiServiceReportDetailDetailWorkingTimes,
  "id" | "constructions" | "uuid"
> & {
  id?: number;
  workerIndex?: number; // 下書き用の値
  constructions: { id?: number; key?: string }[];
  uuid?: string;
};

/**
 * サービスレポート明細情報のステータス
 */
export type ServiceReportDetailStatus =
  | WebApiServiceReportDetailStatus
  | typeof SERVICE_REPORT_OFFLINE_STATUS;

export const serviceReportDetailStatus = {
  OFFLINE: SERVICE_REPORT_OFFLINE_STATUS,
  IN_PREPARATION: WebApiServiceReportDetailStatus.NUMBER_1,
  CHECKING: WebApiServiceReportDetailStatus.NUMBER_2,
  CHECK_COMPLETED: WebApiServiceReportDetailStatus.NUMBER_3,
};

export const serviceReportDetailStatusText = {
  [serviceReportDetailStatus.OFFLINE]: "Offline",
  [serviceReportDetailStatus.IN_PREPARATION]: "In Preparation",
  [serviceReportDetailStatus.CHECKING]: "Under Review",
  [serviceReportDetailStatus.CHECK_COMPLETED]: "Approved",
};

/**
 * サービスレポート明細情報の下書き
 */
export type ServiceReportDetailDraft = {
  id?: string;
  key: string;
  serviceReportId: string;
  fShipNo: string;
  constructions: ServiceReportDetailDetailConstruction[];
  workingTimes: Array<
    ServiceReportDetailDetailWorkingTimes & {
      // ヘッダ情報が下書きの場合は作業者を登録できないため、入力値通りに順序を保持する
      workerIndex?: number;
    }
  >;
  updatedAt: string;
};

/**
 * サービスレポート明細の登録用引数
 */
export type ServiceReportDetailCreate = WebApiServiceReportDetailCreate;

/**
 * サービスレポート明細の更新用引数
 */
export type ServiceReportDetailUpdate = WebApiServiceReportDetailUpdate;

/**
 * サービスレポート明細に紐づく工事情報の添付ファイル一括更新用引数
 */
export type ServiceReportDetailConstructionFilesUpdate = Omit<
  WebApiServiceReportDetailConstructionsFiles,
  "constructions"
> & {
  constructions: Array<
    Omit<ServiceReportDetailConstructionsFilesConstructions, "files"> & {
      files: Array<File | AttachmentFile>;
    }
  >;
};

/**
 * サービスレポート明細.工事情報の完工ステータス
 */
export const serviceReportConstructionCompleteStatus = {
  COMPLETE: ServiceReportConstructionCompleteStatus.NUMBER_2,
  IN_COMPLETE: ServiceReportConstructionCompleteStatus.NUMBER_1,
};
export const serviceReportConstructionCompleteStatusText = {
  [serviceReportConstructionCompleteStatus.COMPLETE]: "Complete",
  [serviceReportConstructionCompleteStatus.IN_COMPLETE]: "In-Complete",
};

/**
 * サービスレポート明細.工数情報の日付種別
 */
export const serviceReportDateType = {
  WEEK: WebApiServiceReportSaveType.NUMBER_1,
  HOLIDAY: WebApiServiceReportSaveType.NUMBER_2,
};
export const serviceReportDateTypeText = {
  [serviceReportDateType.WEEK]: "Weekday",
  [serviceReportDateType.HOLIDAY]: "Weekend Day",
};

/**
 * 工事情報の型式名称の取得
 *
 * @param base 型式名
 * @param suffix 追加分（識別名）
 * @returns 型式名
 */
export function getConstructionModelType(base: string, suffix?: string) {
  if (!suffix) return base;
  return `${base} ${suffix}`;
}

/**
 * 工事情報の表示名称の取得
 *
 * @param construction 工事情報
 * @param seq 連番
 * @param displaySerialNo 機番の表示フラグ
 * @return 表示名称
 */
export function getConstructionName(
  construction: Pick<
    ServiceReportDetailDetailConstruction,
    "modelType" | "identificationName" | "serialNo"
  >,
  seq: number,
  displaySerialNo = true
) {
  const constructionName =
    String(seq) +
    ". " +
    (construction.modelType || "-") +
    " / " +
    (construction.identificationName || "-");

  return displaySerialNo
    ? constructionName + " / " + (construction.serialNo || "-")
    : constructionName;
}

/**
 * 使用部品の表示名称の取得
 *
 * @param usedParts 使用部品
 * @return 表示名称
 */
export function getUsedPartItemName(
  usedParts: ServiceReportDetailDetailConstructionUsedParts
) {
  if (usedParts.itemCode && usedParts.partsType) {
    return usedParts.partsType + "　" + usedParts.description;
  }
  return usedParts.description || "-";
}
