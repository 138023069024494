import { UserRole, SapliUser as WebApiSapliUser } from "../webapi/generated";

/**
 * ユーザー
 *
 * サービスアプリの (一般) 利用者
 */
export interface User {
  id: number;
  name: string;
}

export type SerializedUser = User & {
  lastLoginAt: string;
  role: UserRole;
};

export type SapliUser = WebApiSapliUser;

export type SelecatableUserWithSelfFlag = SapliUser & {
  isSelf: boolean;
};

/**
 * 管理者権限の判定
 *
 * @param role ユーザ権限
 * @returns 判定結果
 */
export function isAdminUser(role?: UserRole): boolean {
  return role === UserRole.SystemAdministrator;
}
