/**
 * iPad Safari 判定
 *
 * @returns
 */
export function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();

  return (
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  );
}
