import store, { actions } from "../../store";
import type { PartialDeps } from "../types";
import { catchNotLoginError, getAuthedWebapi } from "./custom-webapi";

export async function checkLogin({ navigate }: PartialDeps<"navigate">) {
  await catchNotLoginError({ navigate }, async () => {
    await getAuthedWebapi();
  });
}

export async function fetchUser() {
  const { connection } = store.getState().setting;
  if (!connection) return;

  const webapi = await getAuthedWebapi();
  const user = await webapi.getUser();

  store.dispatch(
    actions.account.userReceived({
      id: user.id!,
      name: user.name,
      lastLoginAt: new Date(user.lastLoginAt!).toISOString(),
      role: user.role,
    })
  );

  // Google Analytics に User ID を設定する
  // @see https://support.google.com/analytics/answer/3123662?hl=ja
  // @see https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#set_user_id
  if (user.id !== undefined) {
    gtag("set", "user_id", String(user.id));
    gtag("set", "user_properties", {
      user_id_custom: String(user.id),
    });
  }
}
